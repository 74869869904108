import dynamic from 'next/dynamic'
import { useAtom, useSetAtom } from 'jotai'

import { useFormContext } from '@/components'
import {
  CHAIN_INFO_LIST,
  CHAIN_SUPPORTS_AUTOPILOT_LS,
  CHAIN_SUPPORTS_GAS_AUTO_ALLOCATION,
  CHAIN_SUPPORTS_POOL_NUDGE,
  STRD_FEE_TOKEN_MINIMUM,
  config
} from '@/config'
import { useSelectedCoin, useStrideBalances, useWallet } from '@/contexts/wallet'
import { convertDenomToMicroDenom } from '@/wallet-utils'
import {
  isPoolNudgeModalOpenAtom,
  stakeAmountAtom,
  stakeAutopilotModalIsOpenAtom,
  stakeClassicModalIsOpenAtom
} from '../atoms'
import { useStake } from '../StakeProvider'
import { useMarketPriceQuery } from '../queries'
import { isMarketPriceWithinThreshold } from '../utils'
import { useStakeFeeTokensModal } from '../StakeFeeTokensModal'
import { StakeFormType } from './useStakeForm'
import { StakeLsmForm } from './StakeLsmForm'
import { StakeDefaultForm } from './StakeDefaultForm'
import { StakeAutopilotRecipientSelection } from './StakeAutopilotRecipientSelection'
import { Group, Space, Text } from '@mantine/core'
import { StakeToggle } from './StakeToggle'

const StakeButton = dynamic<{}>(() => import('./StakeButton').then((module) => module.StakeButton), { ssr: false })

interface Props {
  amountInputRef: React.RefObject<HTMLInputElement>
}

const StakeForm: React.FC<Props> = ({ amountInputRef }) => {
  const { selectedCoinDenom } = useWallet()

  // @TODO: Use StrideBalancesV2
  const { strideBalance, strideSelectedAccountBalance } = useStrideBalances()

  const denom = useSelectedCoin()

  const [_, setAmount] = useAtom(stakeAmountAtom)

  const setStakeClassicModalIsOpen = useSetAtom(stakeClassicModalIsOpenAtom)

  const setStakeAutopilotModalIsOpen = useSetAtom(stakeAutopilotModalIsOpenAtom)

  const setIsPoolNudgeModalOpen = useSetAtom(isPoolNudgeModalOpenAtom)

  const { values, onSubmit } = useFormContext<StakeFormType>()

  const { data: marketPrice } = useMarketPriceQuery()

  const { mode, openLsm } = useStake()

  const { setIsFeeTokensModalOpen } = useStakeFeeTokensModal()

  const handleSubmitLsmStakeForm = () => {
    // Auto-allocation cannot cover LSM given it uses tokenized shares instead of tokens
    const isFeeTokenNecessary = BigInt(strideBalance) < STRD_FEE_TOKEN_MINIMUM

    if (isFeeTokenNecessary) {
      setIsFeeTokensModalOpen(true)
    } else {
      openLsm({ amount: Number(values.lsmAmount) })
    }
  }

  const handleSubmitDefaultStakeForm = () => {
    const amount = Number(values.defaultAmount)

    setAmount(amount)

    const chainInfo = CHAIN_INFO_LIST[denom]

    const isViableToApplyStrideBalance =
      BigInt(strideSelectedAccountBalance) >=
      convertDenomToMicroDenom(config.staking.strideBalanceThreshold, chainInfo.stakeCurrency.coinMinimalDenom)

    const isUsingClassicLiquidStaking = isViableToApplyStrideBalance || !CHAIN_SUPPORTS_AUTOPILOT_LS[selectedCoinDenom]

    const isFeeTokenNecessary =
      !CHAIN_SUPPORTS_GAS_AUTO_ALLOCATION[denom] && BigInt(strideBalance) < STRD_FEE_TOKEN_MINIMUM

    if (CHAIN_SUPPORTS_POOL_NUDGE[selectedCoinDenom] && isMarketPriceWithinThreshold({ amount, marketPrice })) {
      setIsPoolNudgeModalOpen(true)
    } else if (isFeeTokenNecessary && isUsingClassicLiquidStaking) {
      setIsFeeTokensModalOpen(true)
    } else if (isUsingClassicLiquidStaking) {
      setStakeClassicModalIsOpen(true)
    } else {
      setStakeAutopilotModalIsOpen(true)
    }
  }

  const handleSubmit = () => {
    if (mode === 'lsm') {
      handleSubmitLsmStakeForm()
    } else {
      handleSubmitDefaultStakeForm()
    }
  }

  return (
    <>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Group position="apart" sx={{ height: 24 }}>
          <Text inline sx={(t) => ({ color: t.colors.gray[7] })}>
            Amount:
          </Text>

          <StakeToggle />
        </Group>

        <Space h="sm" />

        {mode === 'default' ? (
          <StakeDefaultForm amountInputRef={amountInputRef} />
        ) : (
          <StakeLsmForm amountInputRef={amountInputRef} />
        )}

        <StakeButton />

        <StakeAutopilotRecipientSelection />
      </form>
    </>
  )
}

export { StakeForm }
