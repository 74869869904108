import dynamic from 'next/dynamic'
import { Container, Paper, Space, Stack, Tooltip, useMantineTheme, createStyles } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import * as Tabs from '@radix-ui/react-tabs'
import { motion } from 'framer-motion'

import { CHAIN_SUPPORTS_LSM } from '@/config'
import { useAutoFocusRef } from '@/hooks'
import { TextHeadingAlignmentFix, FormProvider } from '@/components'
import { useSelectedCoin } from '@/contexts/wallet'
import { StakeCoinSelector } from './StakeCoinSelector'
import { StakeForm, useStakeForm } from './StakeForm'
import { StakeLsmModal, StakeLsmModalProvider } from './StakeLsmModal'
import { StakeClassicModal, StakeClassicModalProvider } from './StakeClassicModal'
import { StakeAutopilotModal, StakeAutopilotModalProvider } from './StakeAutopilotModal'
import { StakeStats } from './StakeStats'
import { UnstakeForm, useUnstakeForm } from './UnstakeForm'
import { UnstakeModal, UnstakeModalProvider } from './UnstakeModal'
import { WithdrawStTokenToDexModal, WithdrawStTokenToDexProvider } from './WithdrawStTokenToDexModal'
import { StakeTransactionHistory } from './StakeTransactionHistory'
import { WithdrawModal } from './WithdrawModal'
import { WithdrawStTokenModal, WithdrawStTokenProvider } from './WithdrawStTokenModal'
import { WithdrawLsmModal, WithdrawLsmModalProvider } from './WithdrawLsmModal'
import { WithdrawLsmFromTokenizeModal, WithdrawLsmFromTokenizeModalProvider } from './WithdrawLsmFromTokenizeModal'
import { LsmTutorialModal } from './LsmTutorialModal'
import { StakeFeeTokensModal } from './StakeFeeTokensModal'
import { StakeChainHaltedCard } from './StakeChainHaltedCard'
import { PoolNudgeModal } from './PoolNudgeModal'
import { useStake } from './StakeProvider'
import { useLsmValidatorsQuery, useRateLimitQuery } from './queries'
import { getTotalNativelyStakedBalance } from './utils'
import { StakeDydxBanner } from './StakeDydxBanner'
import { StakeDymBanner } from './StakeDymBanner'
import { useState } from 'react'

const useStyles = createStyles((t) => ({
  tabRoot: {
    display: 'flex',
    flexDirection: 'column'
  },
  tabList: {
    position: 'relative',
    display: 'flex',
    backgroundColor: '#FAF6F8',
    padding: 3,
    borderRadius: 32
  },
  tabTrigger: {
    all: 'unset',
    position: 'relative',
    padding: '8px 0',
    fontFamily: t.other.headingFontFamily,
    fontWeight: 600,
    fontSize: 14,
    textTransform: 'uppercase',
    width: '50%',
    textAlign: 'center',
    color: t.colors.gray[7],
    cursor: 'pointer',
    transition: 'color 0.15s ease-in-out',
    '&[data-state="active"]': {
      color: t.colors.gray[9]
    }
  },
  tabTriggerAnimate: {
    position: 'absolute',
    backgroundColor: t.white,
    inset: 0,
    borderRadius: 32
  }
}))

const StakeWallet = dynamic<{}>(() => import('./StakeWallet').then((module) => module.StakeWallet), { ssr: false })

const Stake: React.FC = () => {
  const { lsmTutorialTooltip, setMode, setLsmValidatorAddress } = useStake()

  const { data: lsmValidators } = useLsmValidatorsQuery()

  // We want to make sure rate limit is preloaded
  useRateLimitQuery()

  const denom = useSelectedCoin()

  const stakeForm = useStakeForm()

  const unstakeForm = useUnstakeForm()

  const stakeAmountInputRef = useAutoFocusRef()

  const unstakeAmountInputRef = useAutoFocusRef()

  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  const { classes } = useStyles()

  const [activeTab, setActiveTab] = useState(0)

  const handleDenomChange = () => {
    // We want to make sure the amount field is reset when the user switches to another chain.
    stakeForm.reset()

    if (!CHAIN_SUPPORTS_LSM[denom]) {
      // We want to switch back to default mode if the chain we're switching to doesn't support LSM.
      setMode('default')
    }

    // We want to make sure previously selected validator is unselected when user switches to another chain.
    setLsmValidatorAddress('')
  }

  const handleStakeLsmSuccess = () => {
    // I have a hunch there will be a bug here when we do the integration because
    // lsmValidators will have stale values when we get here, I think. We'll see.
    // If the user has no natively staked balance, we should switch back to the default mode.
    if (!getTotalNativelyStakedBalance({ lsmValidators })) {
      setMode('default')
    }

    stakeForm.reset()
  }

  const handleStakeDefaultSuccess = () => {
    stakeForm.reset()
  }

  const handleClaimSuccess = () => {
    unstakeForm.reset()
  }

  const tabs = [
    {
      id: 'liquid-stake',
      label: 'Liquid Stake'
    },
    {
      id: 'redeem',
      label: 'Redeem'
    }
  ]

  return (
    <>
      <Container size={500} px={isMediumScreen ? 'xs' : 'sm'} pt={isMediumScreen ? 'xl' : 'sm'}>
        <StakeCoinSelector onChange={handleDenomChange} />

        <Stack spacing="md">
          <StakeChainHaltedCard />

          <StakeTransactionHistory />

          <StakeDydxBanner />

          <StakeDymBanner />

          <Tooltip
            opened={lsmTutorialTooltip.isOpen}
            label="Select the validator that you want to liquid stake from"
            withinPortal
            wrapLines
            withArrow
            position="right"
            width={220}>
            <Paper p="xl">
              <Tabs.Root defaultValue="liquid-stake" className={classes.tabRoot}>
                <Tabs.List className={classes.tabList}>
                  {tabs.map((tab, i) => (
                    <Tabs.Trigger key={i} value={tab.id} className={classes.tabTrigger} onClick={() => setActiveTab(i)}>
                      {activeTab === i && (
                        <motion.div
                          layoutId="tab-trigger"
                          className={classes.tabTriggerAnimate}
                          transition={{ duration: 0.15 }}
                        />
                      )}

                      {/* `tab-trigger` is rendered on top when we are animating from ltr. Adding zIndex fixes the issue */}
                      {/* for more context, watch this video: https://youtu.be/kep_Iaxuzy0?si=Xs_315PyTjTUUhmf&t=290 */}
                      <TextHeadingAlignmentFix adjustment="sm" sx={{ position: 'relative', zIndex: 10 }}>
                        {tab.label}
                      </TextHeadingAlignmentFix>
                    </Tabs.Trigger>
                  ))}
                </Tabs.List>

                <Space h="lg" />

                <Tabs.Content value="liquid-stake">
                  <FormProvider {...stakeForm}>
                    <StakeForm amountInputRef={stakeAmountInputRef} />

                    <StakeLsmModalProvider>
                      <StakeLsmModal onSuccess={handleStakeLsmSuccess} />
                    </StakeLsmModalProvider>

                    <StakeClassicModalProvider>
                      <StakeClassicModal onSuccess={handleStakeDefaultSuccess} />
                    </StakeClassicModalProvider>

                    <StakeAutopilotModalProvider>
                      <StakeAutopilotModal onSuccess={handleStakeDefaultSuccess} />
                    </StakeAutopilotModalProvider>
                  </FormProvider>
                </Tabs.Content>

                <Tabs.Content value="redeem">
                  <FormProvider {...unstakeForm}>
                    <UnstakeForm amountInputRef={unstakeAmountInputRef} />

                    <UnstakeModalProvider>
                      <UnstakeModal onSuccess={handleClaimSuccess} />
                    </UnstakeModalProvider>
                  </FormProvider>
                </Tabs.Content>
              </Tabs.Root>
            </Paper>
          </Tooltip>

          <StakeWallet />

          <StakeStats />
        </Stack>
      </Container>

      <LsmTutorialModal />

      <PoolNudgeModal />

      <WithdrawModal />

      <WithdrawStTokenToDexProvider>
        <WithdrawStTokenToDexModal />
      </WithdrawStTokenToDexProvider>

      <WithdrawStTokenProvider>
        <WithdrawStTokenModal />
      </WithdrawStTokenProvider>

      <WithdrawLsmModalProvider>
        <WithdrawLsmModal />
      </WithdrawLsmModalProvider>

      <WithdrawLsmFromTokenizeModalProvider>
        <WithdrawLsmFromTokenizeModal />
      </WithdrawLsmFromTokenizeModalProvider>

      <StakeFeeTokensModal />
    </>
  )
}

export { Stake }
