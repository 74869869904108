import React, { useMemo } from 'react'
import { Text, Tooltip, Group, Box, createStyles } from '@mantine/core'
import * as Switch from '@radix-ui/react-switch'

import { ErrorBeacon, InlineLoader } from '@/components'
import { CHAIN_SUPPORTS_LSM } from '@/config'
import { useSelectedCoin, useStrideWallet } from '@/contexts/wallet'
import { useStake } from '../StakeProvider'
import { useHostZoneQuery, useLsmValidatorsQuery } from '../queries'
import { getTotalNativelyStakedBalance } from '../utils'

const useStyles = createStyles((t) => ({
  switchRoot: {
    all: 'unset',
    width: 26,
    height: 16,
    backgroundColor: t.colors.gray[3],
    borderRadius: 9999,
    position: 'relative',
    transition: 'background 150ms ease-in-out',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    '&[data-state="checked"]': {
      backgroundColor: t.colors.pink[6]
    }
  },
  switchThumb: {
    display: 'block',
    width: 11,
    height: 11,
    backgroundColor: t.white,
    borderRadius: 9999,
    transition: 'transform 150ms ease-in-out',
    transform: 'translateX(3px)',
    willChange: 'transform',
    '&[data-state="checked"]': {
      transform: 'translateX(12px)'
    }
  }
}))

const StakeToggle: React.FC = () => {
  const denom = useSelectedCoin()

  const strideAccount = useStrideWallet()

  const { mode, setMode } = useStake()

  const { data: lsmValidators, error: lsmValidatorsError } = useLsmValidatorsQuery()

  const { data: hostZone, error: hostZoneError } = useHostZoneQuery()

  const { classes } = useStyles()

  const totalNativelyStakedBalance = useMemo(() => {
    return getTotalNativelyStakedBalance({ lsmValidators })
  }, [lsmValidators])

  const handleChange = () => {
    setMode(mode === 'default' ? 'lsm' : 'default')
  }

  const isEnabled = !lsmValidatorsError && !hostZoneError && totalNativelyStakedBalance > 0

  // `isLsmValidatorsLoading` isn't set to `true` while its dependencies are loading (stride and selected account)
  // This makes sure we're able to cover this + hide the loader while wallet is disconnected.
  const isQueryLoading = (!lsmValidators && !lsmValidatorsError) || (!hostZone && !hostZoneError)

  const isLoading = strideAccount && isQueryLoading

  const errorMessage = lsmValidatorsError
    ? `Unable to fetch your validator's balance. Please refresh the page.`
    : hostZoneError
    ? `Unable to fetch your selected chain's host zone information. Please refresh the page.`
    : ''

  // Tooltip is intentionally hidden if there's an error message; we only show one or the other.
  const tooltipMessage = errorMessage
    ? ''
    : !strideAccount
    ? 'You need to be connected to a wallet to use this feature.'
    : !isLoading && !isEnabled
    ? 'You don’t have any natively staked ATOM in this wallet.'
    : ''

  if (!CHAIN_SUPPORTS_LSM[denom]) {
    return null
  }

  return (
    <>
      <Tooltip label={tooltipMessage} disabled={!tooltipMessage}>
        {isLoading ? (
          <InlineLoader />
        ) : errorMessage ? (
          <Box pl={4} sx={{ lineHeight: 1 }}>
            <ErrorBeacon label={errorMessage} />
          </Box>
        ) : (
          <Group spacing={10}>
            <Switch.Root checked={mode === 'lsm'} onCheckedChange={handleChange} className={classes.switchRoot}>
              <Switch.Thumb className={classes.switchThumb} />
            </Switch.Root>

            <Text sx={(t) => ({ color: !isEnabled ? t.colors.gray[5] : t.colors.gray[7] })}>Use staked balance</Text>
          </Group>
        )}
      </Tooltip>
    </>
  )
}

export { StakeToggle }
