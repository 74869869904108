import React from 'react'
import { Group, Text } from '@mantine/core'
import BigNumber from 'bignumber.js'

import { useStakeStatsQuery } from '@/queries'
import { CoinSelector } from '@/page-components/shared'
import { InlineLoader, TextHeading, TextHeadingAlignmentFix } from '@/components'
import { useMediumScreen } from '@/hooks'
import { FEATURE_FLAGS } from '@/feature-flags'
import { useSelectedCoin } from '@/contexts/wallet'

interface StakeCoinSelectorProps {
  onChange?: () => void
}

const StakeCoinSelector: React.FC<StakeCoinSelectorProps> = ({ onChange }) => {
  const selectedCoinDenom = useSelectedCoin()

  const { data: stakeStats, isPending: isStakeStatsPending } = useStakeStatsQuery()

  const strideYield = stakeStats
    ? new BigNumber(stakeStats[selectedCoinDenom].strideYield).multipliedBy(100).decimalPlaces(2).toString()
    : '0'

  const { isMediumScreen } = useMediumScreen()

  return (
    <Group position="apart" align="center" pb="md">
      {FEATURE_FLAGS.sidebar && isMediumScreen && <TextHeading>{selectedCoinDenom}</TextHeading>}
      {(!FEATURE_FLAGS.sidebar || !isMediumScreen) && <CoinSelector onChange={onChange} />}

      {isStakeStatsPending ? (
        <InlineLoader />
      ) : (
        <Group spacing={4} align="flex-end">
          <Group spacing={2} align="flex-end">
            <TextHeadingAlignmentFix>
              <TextHeading sx={(t) => ({ color: t.colors.gray[9], fontSize: 28, fontWeight: 600, lineHeight: '20px' })}>
                {strideYield}
              </TextHeading>
            </TextHeadingAlignmentFix>

            <Text
              sx={(t) => ({
                color: t.colors.gray[9],
                fontWeight: 700,
                lineHeight: 1
              })}>
              %
            </Text>
          </Group>

          <TextHeadingAlignmentFix>
            <TextHeading sx={(t) => ({ color: t.colors.gray[9], fontSize: 14, fontWeight: 600, lineHeight: 1 })}>
              APY
            </TextHeading>
          </TextHeadingAlignmentFix>
        </Group>
      )}
    </Group>
  )
}

export { StakeCoinSelector }
