import { useMemo, useState } from 'react'
import { Anchor, Alert, Button, Group, Space, Text, TextInput, Paper, Stack } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import BigNumber from 'bignumber.js'

import { TextLoader, useFormContext } from '@/components'
import { config, CHAIN_CONFIG, INJ_CHAIN_INFO, STRIDE_CHAIN_INFO, CHAIN_INFO_LIST, ISLM_CHAIN_INFO } from '@/config'
import { useSelectedBalancesV2, useSelectedCoin, useStrideBalancesV2, useStrideWallet } from '@/contexts/wallet'
import { convertMicroDenomToDenom, formatMicroDenom } from '@/wallet-utils'
import { ChainIcon } from '@/page-components/shared'
import { StakeEstimate } from '../StakeEstimate'
import { StakeFormType } from './useStakeForm'

interface Props {
  amountInputRef: React.RefObject<HTMLInputElement>
}

const StakeDefaultForm: React.FC<Props> = ({ amountInputRef }) => {
  const selectedCoinDenom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[selectedCoinDenom]

  const minimum = CHAIN_CONFIG[selectedCoinDenom].classicLsMinimum

  const strideAccount = useStrideWallet()

  const { data: strideBalances, isLoading: isStrideBalancesLoading } = useStrideBalancesV2()

  const chainBalanceOnStride = BigInt(strideBalances?.strideSelectedAccountBalance ?? 0)

  const { data: selectedBalances, isLoading: isSelectedBalancesLoading } = useSelectedBalancesV2()

  const chainBalance = BigInt(selectedBalances?.selectedAccountBalance ?? 0)

  const [isFocused, setIsFocused] = useState(false)

  const { values, setFieldValue, validate } = useFormContext<StakeFormType>()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const totalBalance = chainBalance + chainBalanceOnStride

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const recommendedMaxAmount = useMemo(() => {
    const totalBalanceInDenom = convertMicroDenomToDenom(totalBalance, chainInfo.stakeCurrency.coinMinimalDenom)

    const max = new BigNumber(totalBalanceInDenom)
      .minus(CHAIN_CONFIG[selectedCoinDenom].minimumGas)
      .decimalPlaces(5, BigNumber.ROUND_DOWN)
      .toNumber()

    return Math.max(max, 0)
  }, [totalBalance, selectedCoinDenom])

  const halfAmount = useMemo(() => {
    const totalBalanceInDenom = convertMicroDenomToDenom(totalBalance, chainInfo.stakeCurrency.coinMinimalDenom)

    const halfValue = new BigNumber(totalBalanceInDenom)
      .dividedBy(2)
      .minus(CHAIN_CONFIG[selectedCoinDenom].minimumGas)
      .decimalPlaces(5, BigNumber.ROUND_DOWN)
      .toNumber()

    return Math.max(halfValue, 0)
  }, [totalBalance, selectedCoinDenom])

  const handleClickMax = () => {
    setFieldValue('defaultAmount', recommendedMaxAmount.toString())
  }

  const handleClickHalf = () => {
    setFieldValue('defaultAmount', halfAmount.toString())
  }

  useDidUpdate(() => {
    if (!isFocused) return
    // We only want to validate if the user changed something,
    // but not if reset by a successful stake
    // We're strictly not validating when focus changes as it
    // causes the form to validate when we autofocus on mount
    // or when stake modal closes and reset the form
    const { errors } = validate()

    // We've removed the validation error where values.defaultAmount is required.
    if (typeof errors.defaultAmount === 'string' && values.defaultAmount !== '') {
      // We are storing the value of `errors.defaultAmount` as an internal state
      // because this solves an issue where the UI starts stuttering as the user
      // types on the input field.

      // @TODO: figure out why the stuttering happens and fix it
      setErrorMessage(errors.defaultAmount)
    } else {
      setErrorMessage(null)
    }
  }, [values.defaultAmount])

  const isTransferringAboveRecommendedBalance = useMemo(() => {
    const totalBalanceInDenom = convertMicroDenomToDenom(totalBalance, chainInfo.stakeCurrency.coinMinimalDenom)

    // If user is staking more than their total balance, we'll let the validation take care of it.
    if (Number(values.defaultAmount) > Number(totalBalanceInDenom)) {
      return false
    }

    return Number(values.defaultAmount) > recommendedMaxAmount
  }, [values, totalBalance, recommendedMaxAmount])

  const isUsingInjective = selectedCoinDenom === INJ_CHAIN_INFO.stakeCurrency.coinDenom

  const isUsingIslm = selectedCoinDenom === ISLM_CHAIN_INFO.stakeCurrency.coinDenom

  const amountOption = useMemo(() => {
    if (values.defaultAmount === recommendedMaxAmount.toString()) return 'max'
    if (values.defaultAmount === halfAmount.toString()) return 'half'

    return null
  }, [values, recommendedMaxAmount, halfAmount])

  const formattedTotalBalance = formatMicroDenom(totalBalance, chainInfo.stakeCurrency.coinMinimalDenom, 5)

  return (
    <>
      <Paper p="md" sx={{ backgroundColor: '#FAF6F8' }}>
        <Group position="apart" align="center">
          <Group spacing={0}>
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>Available:</Text>
            &nbsp;
            <TextLoader
              loading={isStrideBalancesLoading || isSelectedBalancesLoading}
              sx={(t) => ({ color: t.colors.gray[7] })}>
              {strideAccount && `${formattedTotalBalance} ${selectedCoinDenom}`}
            </TextLoader>
          </Group>

          <Group spacing="xs">
            <Button
              size="xs"
              onClick={handleClickHalf}
              // We're disabling this button if the user has less than the minimum amount
              disabled={Number(formattedTotalBalance) <= minimum}
              sx={(t) => ({
                width: 44,
                height: 24,
                padding: 0,
                borderRadius: 4,
                backgroundColor: amountOption === 'half' ? t.colors.gray[7] : t.colors.gray[4],
                transition: 'background 0.1s',
                '&:not(.__mantine-ref-loading):disabled': {
                  backgroundColor: t.colors.gray[4],
                  color: t.white
                },
                '&:not(:disabled):active': {
                  backgroundColor: t.colors.pink[7],
                  transform: 'translateY(0)'
                }
              })}>
              <Text sx={(t) => ({ color: t.white })}>Half</Text>
            </Button>

            <Button
              size="xs"
              onClick={handleClickMax}
              // We're disabling this button if the user has less than the minimum amount
              disabled={Number(formattedTotalBalance) <= minimum}
              sx={(t) => ({
                width: 44,
                height: 24,
                padding: 0,
                borderRadius: 4,
                backgroundColor: amountOption === 'max' ? t.colors.gray[7] : t.colors.gray[4],
                transition: 'background 0.1s',
                '&:not(.__mantine-ref-loading):disabled': {
                  backgroundColor: t.colors.gray[4],
                  color: t.white
                },
                '&:not(:disabled):active': {
                  backgroundColor: t.colors.pink[7],
                  transform: 'translateY(0)'
                }
              })}>
              <Text sx={(t) => ({ color: t.white })}>Max</Text>
            </Button>
          </Group>
        </Group>

        <Space h="xs" />

        <Paper
          p="sm"
          sx={(t) => ({
            backgroundColor: t.white,
            transition: 'border 0.15s ease-in-out',
            border: `2px solid ${isFocused ? t.colors.gray[3] : 'transparent'}`
          })}
          onClick={() => amountInputRef.current?.focus()}>
          <Group position="apart" noWrap>
            <Group spacing={10} noWrap sx={{ width: 'fit-content' }}>
              <ChainIcon denom={selectedCoinDenom} size={40} />

              <Stack spacing={4}>
                <Text size="xl" weight={700} sx={(t) => ({ color: t.colors.gray[9], lineHeight: 1 })}>
                  {selectedCoinDenom}
                </Text>

                <Text sx={(t) => ({ color: t.colors.gray[7], lineHeight: 1, whiteSpace: 'nowrap' })}>
                  {chainInfo.chainName}
                </Text>
              </Stack>
            </Group>

            <Stack spacing={0} align="flex-end">
              <TextInput
                ref={amountInputRef}
                required
                size="xs"
                sx={(t) => ({
                  input: {
                    fontWeight: 700,
                    fontSize: 20,
                    textAlign: 'right',
                    color: t.colors.gray[9],
                    padding: 0,
                    border: 'none',
                    borderRadius: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    '&::placeholder': { color: t.colors.gray[9] }
                  }
                })}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue('defaultAmount', e.target.value)}
                value={values.defaultAmount}
              />

              <StakeEstimate hideDenomEstimate />
            </Stack>
          </Group>
        </Paper>
      </Paper>

      {errorMessage && (
        <>
          <Space h="xs" />

          <Text sx={(t) => ({ color: t.colors.red[7] })}>{errorMessage}</Text>
        </>
      )}

      {/* This is intentional. All conditions use the same UI, but we only want one to appear at a time. Recommended balance warning takes precedence if it exists. */}

      {isTransferringAboveRecommendedBalance && (
        <>
          <Space h="xs" />

          <Alert color="yellow">
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>
              It looks like you're transferring close to your full balance. We recommend you leave at least 0.1{' '}
              {selectedCoinDenom} in your wallet to cover the gas fees associated with liquid staking. The transaction
              may fail if you run out of gas.
            </Text>
          </Alert>
        </>
      )}

      {!isTransferringAboveRecommendedBalance && isUsingInjective && (
        <>
          <Space h="xs" />

          <Alert color="yellow">
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>
              Ledger support coming soon, for now, please use the{' '}
              <Anchor href={config.links.injectiveBridge} target="_blank">
                Injective bridge
              </Anchor>{' '}
              to transfer your {INJ_CHAIN_INFO.stakeCurrency.coinDenom} to {STRIDE_CHAIN_INFO.chainName} using Ledger.
            </Text>
          </Alert>
        </>
      )}

      {!isTransferringAboveRecommendedBalance && isUsingIslm && (
        <>
          <Space h="xs" />

          <Alert color="yellow">
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>Ledger support is coming soon.</Text>
          </Alert>
        </>
      )}

      <Space h="lg" />

      <Group position="apart" align="center" sx={{ height: 50 }}>
        <Text inline sx={(t) => ({ color: !values.defaultAmount ? t.colors.gray[4] : t.colors.gray[7] })}>
          You will get:
        </Text>

        <StakeEstimate />
      </Group>
    </>
  )
}

export { StakeDefaultForm }
