import React, { useMemo } from 'react'
import { Box, Group, Space, Text } from '@mantine/core'
import { useHover } from '@mantine/hooks'

import { ErrorBeacon, InlineLoader, useFormContext } from '@/components'
import { useSelectedCoin } from '@/contexts/wallet'
import { formatDenom, formatCurrency, formatLargeCurrency } from '@/wallet-utils'
import { useTokenValueInUsdQuery } from '@/queries'
import { useHostZoneQuery } from '../queries'
import { useStake } from '../StakeProvider'
import { getLsmEstimatedStTokenValue } from '../utils'
import { StakeFormType } from './useStakeForm'

interface StakeLsmEstimateProps {
  hideDenomEstimate?: boolean
}

const StakeLsmEstimate: React.FC<StakeLsmEstimateProps> = ({ hideDenomEstimate }) => {
  const denom = useSelectedCoin()

  const { lsm } = useStake()

  const { values } = useFormContext<StakeFormType>()

  const { data: stTokenValueInUsd, error: stTokenValueInUsdError } = useTokenValueInUsdQuery(`st${denom}`)

  const { data: hostZone, isLoading: isHostZoneLoading, error: hostZoneError } = useHostZoneQuery()

  const { hovered, ref } = useHover()

  const estimate = useMemo(() => {
    return getLsmEstimatedStTokenValue({
      amount: Number(values.lsmAmount),
      hostZone,
      validatorAddress: lsm.validatorAddress
    })
  }, [lsm, hostZone, values, denom])

  if (hostZoneError) {
    return (
      <Box ref={ref} sx={{ display: 'flex', alignItems: 'center', height: 22 }}>
        <ErrorBeacon label="Unable to fetch redemption rate at this time. Please try again." />
      </Box>
    )
  }

  if (isHostZoneLoading) {
    return (
      <Box ref={ref} sx={{ display: 'flex', alignItems: 'center', height: 22 }}>
        <InlineLoader />
      </Box>
    )
  }

  return (
    <Box ref={ref}>
      {!hideDenomEstimate && (
        <>
          <Text
            weight={700}
            size="xl"
            align="right"
            sx={(t) => ({ color: !values.lsmAmount ? t.colors.gray[4] : t.colors.gray[9] })}
            inline>
            {hovered ? `${format(estimate)} st${denom}` : `${formatLargeCurrency(format(estimate))} st${denom}`}
          </Text>

          <Space h={4} />
        </>
      )}

      <Group spacing="xs" position="right" noWrap sx={{ height: 22 }}>
        <Text
          weight={500}
          align="right"
          size="sm"
          inline
          sx={(t) => ({ color: !values.lsmAmount ? t.colors.gray[4] : t.colors.gray[7] })}>
          <span>≈</span>
        </Text>

        {stTokenValueInUsdError ? (
          <ErrorBeacon label={`Unable to get the dollar value of st${denom}. Please try again.`} />
        ) : stTokenValueInUsd == null ? (
          <InlineLoader />
        ) : (
          <Text
            weight={500}
            align="right"
            size="sm"
            inline
            sx={(t) => ({ color: !values.lsmAmount ? t.colors.gray[4] : t.colors.gray[7] })}>
            {hovered
              ? formatCurrency(estimate * stTokenValueInUsd)
              : formatLargeCurrency(formatCurrency(estimate * stTokenValueInUsd))}
          </Text>
        )}
      </Group>
    </Box>
  )
}

const format = (estimate: number) => {
  if (estimate >= 1) return formatDenom(estimate, 2)
  if (estimate >= 0.1) return formatDenom(estimate, 3)
  if (estimate >= 0.01) return formatDenom(estimate, 4)
  return formatDenom(estimate, 5)
}

export { StakeLsmEstimate }
