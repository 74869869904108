import { useMemo, useState } from 'react'
import { Alert, Button, Group, Paper, Space, Stack, Text, TextInput } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import BigNumber from 'bignumber.js'
import { useSetAtom } from 'jotai'

import { TextLoader, useFormContext } from '@/components'
import { useSelectedCoin, useStrideBalancesV2, useStrideWallet } from '@/contexts/wallet'
import { convertDenomToMicroDenom, convertMicroDenomToDenom, formatMicroDenom } from '@/wallet-utils'
import { ChainIcon } from '@/page-components/shared'
import { isHostZone } from '@/queries'
import { CHAIN_INFO_LIST } from '@/config'
import { isUnstakingModalOpenAtom, unstakeAmountAtom } from '../atoms'
import { useHostZoneQuery } from '../queries'
import { UnstakeButton } from './UnstakeButton'
import { UnstakeEstimate } from './UnstakeEstimate'
import { UnstakeFormType } from './useUnstakeForm'

interface Props {
  amountInputRef: React.RefObject<HTMLInputElement>
}

const UnstakeForm: React.FC<Props> = ({ amountInputRef }) => {
  const strideAccount = useStrideWallet()

  const { data: strideBalances, isLoading: isStrideBalancesLoading } = useStrideBalancesV2()

  const stTokenBalance = strideBalances?.strideSelectedAccountStakedBalance ?? '0'

  const denom = useSelectedCoin()

  const setIsOpen = useSetAtom(isUnstakingModalOpenAtom)

  const setAmount = useSetAtom(unstakeAmountAtom)

  const { values, setFieldValue, onSubmit, validate } = useFormContext<UnstakeFormType>()

  const { data: hostZone } = useHostZoneQuery()

  const [isFocused, setIsFocused] = useState(false)

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const chainInfo = CHAIN_INFO_LIST[denom]

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const recommendedMaxAmount = useMemo(() => {
    const stTokenBalanceInDenom = convertMicroDenomToDenom(stTokenBalance, chainInfo.stakeCurrency.coinMinimalDenom)

    const max = new BigNumber(stTokenBalanceInDenom)
      .minus(0.01) // Minimum gas required to proceed with the transaction
      .decimalPlaces(5, BigNumber.ROUND_DOWN)
      .toNumber()

    return Math.max(max, 0)
  }, [stTokenBalance, denom])

  const halfAmount = useMemo(() => {
    const stTokenBalanceInDenom = convertMicroDenomToDenom(stTokenBalance, chainInfo.stakeCurrency.coinMinimalDenom)

    const halfRoundedDown = new BigNumber(stTokenBalanceInDenom).dividedBy(2).decimalPlaces(5)

    return BigNumber.max(halfRoundedDown, 0)
  }, [stTokenBalance, denom])

  const handleClickMax = () => {
    setFieldValue('amount', recommendedMaxAmount.toString())
  }

  const handleClickHalf = () => {
    setFieldValue('amount', halfAmount.toString())
  }

  const handleSubmit = () => {
    setAmount(Number(values.amount))

    setIsOpen(true)
  }

  useDidUpdate(() => {
    if (!isFocused) return
    // We only want to validate if the user changed something,
    // but not if reset by a successful unstake
    const { errors } = validate()

    // We've removed the validation error where values.amount is required.
    if (typeof errors.amount === 'string' && values.amount !== '') {
      // We are storing the value of `errors.amount` as an internal state
      // because this solves an issue where the UI starts stuttering as the user
      // types on the input field.

      // @TODO: figure out why the stuttering happens and fix it
      setErrorMessage(errors.amount)
    } else {
      setErrorMessage(null)
    }
  }, [values.amount])

  const isTransferringAboveRecommendedBalance = useMemo(() => {
    const totalBalanceInMicroDenom = convertDenomToMicroDenom(stTokenBalance, chainInfo.stakeCurrency.coinMinimalDenom)

    // If user is unstaking more than their total balance, we'll let the validation take care of it.
    if (Number(values.amount) > Number(totalBalanceInMicroDenom)) {
      return false
    }

    return Number(values.amount) > recommendedMaxAmount
  }, [values, stTokenBalance, recommendedMaxAmount])

  const isUnstakedTextEnabled = () => {
    if (hostZone == null) return false

    return Number(isHostZone(hostZone) ? hostZone.total_delegations : hostZone.delegated_balance) === 0
  }

  const amountOption = useMemo(() => {
    if (values.amount === recommendedMaxAmount.toString()) return 'max'
    if (values.amount === halfAmount.toString()) return 'half'

    return null
  }, [values, recommendedMaxAmount, halfAmount])

  const stakedDenom = `st${denom}`

  const formattedStakedBalance = formatMicroDenom(stTokenBalance, chainInfo.stakeCurrency.coinMinimalDenom, 5)

  return (
    <>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Group sx={{ height: 24 }}>
          <Text inline sx={(t) => ({ color: t.colors.gray[7] })}>
            Amount:
          </Text>
        </Group>

        <Space h="sm" />

        <Paper p="md" sx={{ backgroundColor: '#FAF6F8' }}>
          <Group position="apart" align="center">
            <Group spacing={0}>
              <Text sx={(t) => ({ color: t.colors.gray[7] })}>Available:</Text>
              &nbsp;
              <TextLoader loading={isStrideBalancesLoading} sx={(t) => ({ color: t.colors.gray[7] })}>
                {strideAccount && `${formattedStakedBalance} ${stakedDenom}`}
              </TextLoader>
            </Group>

            <Group spacing="xs">
              <Button
                size="xs"
                onClick={handleClickHalf}
                // We're disabling this button if the user has less than the minimum amount
                disabled={Number(formattedStakedBalance) <= 0.01}
                sx={(t) => ({
                  width: 44,
                  height: 24,
                  padding: 0,
                  borderRadius: 4,
                  backgroundColor: amountOption === 'half' ? t.colors.gray[7] : t.colors.gray[4],
                  transition: 'background 0.1s',
                  '&:not(.__mantine-ref-loading):disabled': {
                    backgroundColor: t.colors.gray[4],
                    color: t.white
                  },
                  '&:not(:disabled):active': {
                    backgroundColor: t.colors.pink[7],
                    transform: 'translateY(0)'
                  }
                })}>
                <Text sx={(t) => ({ color: t.white })}>Half</Text>
              </Button>

              <Button
                size="xs"
                onClick={handleClickMax}
                // We're disabling this button if the user has less than the minimum amount
                disabled={Number(formattedStakedBalance) <= 0.01}
                sx={(t) => ({
                  width: 44,
                  height: 24,
                  padding: 0,
                  borderRadius: 4,
                  backgroundColor: amountOption === 'max' ? t.colors.gray[7] : t.colors.gray[4],
                  transition: 'background 0.1s',
                  '&:not(.__mantine-ref-loading):disabled': {
                    backgroundColor: t.colors.gray[4],
                    color: t.white
                  },
                  '&:not(:disabled):active': {
                    backgroundColor: t.colors.pink[7],
                    transform: 'translateY(0)'
                  }
                })}>
                <Text sx={(t) => ({ color: t.white })}>Max</Text>
              </Button>
            </Group>
          </Group>

          <Space h="xs" />

          <Paper
            p="sm"
            sx={(t) => ({
              backgroundColor: t.white,
              transition: 'border 0.15s ease-in-out',
              border: `2px solid ${isFocused ? t.colors.gray[3] : 'transparent'}`
            })}
            onClick={() => amountInputRef.current?.focus()}>
            <Group position="apart" noWrap>
              <Group spacing={10} noWrap sx={{ width: 'fit-content' }}>
                <ChainIcon denom={`st${denom}`} size={40} />

                <Stack spacing={4}>
                  <Text size="xl" weight={700} sx={(t) => ({ color: t.colors.gray[9], lineHeight: 1 })}>
                    {stakedDenom}
                  </Text>

                  <Text sx={(t) => ({ color: t.colors.gray[7], lineHeight: 1, whiteSpace: 'nowrap' })}>Stride</Text>
                </Stack>
              </Group>

              <Stack spacing={0} align="flex-end">
                <TextInput
                  ref={amountInputRef}
                  required
                  size="xs"
                  sx={(t) => ({
                    input: {
                      fontWeight: 700,
                      fontSize: 20,
                      textAlign: 'right',
                      color: t.colors.gray[9],
                      padding: 0,
                      border: 'none',
                      borderRadius: 0,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      '&::placeholder': { color: t.colors.gray[9] }
                    }
                  })}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => setFieldValue('amount', e.target.value)}
                  value={values.amount}
                />

                <UnstakeEstimate hideDenomEstimate />
              </Stack>
            </Group>
          </Paper>
        </Paper>

        {errorMessage && (
          <>
            <Space h="xs" />

            <Text sx={(t) => ({ color: t.colors.red[7] })}>{errorMessage}</Text>
          </>
        )}

        {isTransferringAboveRecommendedBalance && (
          <>
            <Space h="xs" />

            <Alert color="yellow">
              <Text sx={(t) => ({ color: t.colors.gray[7] })}>
                It looks like you're transferring close to your full balance. We recommend you leave at least 0.01 st
                {denom} in your wallet to cover the gas fees associated with liquid staking. The transaction may fail if
                you run out of gas.
              </Text>
            </Alert>
          </>
        )}

        {isUnstakedTextEnabled() && (
          <>
            <Space h="xs" />

            <Alert color="yellow">
              <Text sx={(t) => ({ color: t.colors.gray[7] })}>
                Unstaking will be enabled after the first full delegation cycle, which takes 4 days.
              </Text>
            </Alert>
          </>
        )}

        <Space h="lg" />

        <Group position="apart" align="center" sx={{ height: 50 }}>
          <Text inline sx={(t) => ({ color: !values.amount ? t.colors.gray[4] : t.colors.gray[7] })}>
            You will get:
          </Text>

          <UnstakeEstimate />
        </Group>

        <Space h="lg" />

        <UnstakeButton />
      </form>
    </>
  )
}

export { UnstakeForm }
