import React from 'react'
import { Box, Center, Group, Paper, Space, Stack, Text, UnstyledButton } from '@mantine/core'
import * as Select from '@radix-ui/react-select'
import { useAtom } from 'jotai'

import { Icon, useFormContext } from '@/components'
import {
  CHAIN_INFO_LIST,
  CHAIN_SUPPORTS_AUTOPILOT_LS,
  SUPPORTED_COIN_DENOMS_AUTOPILOT_RECIPIENT,
  config
} from '@/config'
import { useSelectedCoin, useStrideBalancesV2 } from '@/contexts/wallet'
import { fatal } from '@/utils'
import { ChainIcon } from '@/page-components/shared'
import { SelectedCoinDenom, convertDenomToMicroDenom, isSelectedCoinDenom } from '@/wallet-utils'
import { stakeAutopilotRecipientAtom } from '../atoms'
import { useStake } from '../StakeProvider'
import { StakeFormType } from './useStakeForm'

const StakeAutopilotRecipientSelection: React.FC = () => {
  const denom = useSelectedCoin()

  const [stakeAutopilotRecipient, setStakeAutopilotRecipient] = useAtom(stakeAutopilotRecipientAtom)

  const chainInfo = CHAIN_INFO_LIST[denom]

  const { mode } = useStake()

  const { values } = useFormContext<StakeFormType>()

  const handleChange = (value: SelectedCoinDenom) => {
    if (!value) {
      return
    }

    if (!isSelectedCoinDenom(value)) {
      throw fatal(`Selected invalid recepient chain: ${value}`)
    }

    setStakeAutopilotRecipient(value)
  }

  const options = [...SUPPORTED_COIN_DENOMS_AUTOPILOT_RECIPIENT, denom]
    .filter((value, index, coinDenoms) => coinDenoms.indexOf(value) === index)
    .map((coinDenom) => ({
      label: CHAIN_INFO_LIST[coinDenom].chainName,
      value: coinDenom
    }))

  const selected = options.find((option) => {
    return option.value === stakeAutopilotRecipient
  })

  const { data: strideBalances } = useStrideBalancesV2()

  const chainBalanceOnStride = BigInt(strideBalances?.strideSelectedAccountBalance ?? '0')

  const isViableToApplyStrideBalance =
    chainBalanceOnStride >=
    convertDenomToMicroDenom(config.staking.strideBalanceThreshold, chainInfo.stakeCurrency.coinMinimalDenom)

  if (selected == null) {
    throw fatal('Unable to find selected option.')
  }

  // This means that the user will open LSM Liquid Staking
  // @TODO: Consider moving this condition to `StakeProvider` since this is duplicated in `StakeForm`

  if (mode === 'lsm') {
    return null
  }

  // This means that the user is going to open Classic Liquid Staking
  // @TODO: Consider moving this condition to `StakeProvider` since this is duplicated in `StakeForm`
  if (isViableToApplyStrideBalance || !CHAIN_SUPPORTS_AUTOPILOT_LS[denom]) {
    return null
  }

  return (
    <>
      <Space h="md" />

      <Center>
        <Group spacing="xs">
          <Text size="sm" sx={(t) => ({ color: !values.defaultAmount ? t.colors.gray[4] : t.colors.gray[7] })}>
            Receive on
          </Text>

          <Selection value={stakeAutopilotRecipient} options={options} onChange={handleChange} />
        </Group>
      </Center>
    </>
  )
}

interface SelectionOption {
  value: string
  label: string
}

interface SelectionProps {
  options: SelectionOption[]
  value: SelectedCoinDenom
  onChange: (value: SelectedCoinDenom) => void
}

function Selection(props: SelectionProps) {
  const { options, value, onChange } = props

  const { values } = useFormContext<StakeFormType>()

  const selected = options.find((option) => {
    return option.value === value
  })

  if (selected == null) {
    throw fatal('Unable to find selected option.')
  }

  return (
    <Select.Root value={selected.value} onValueChange={onChange} disabled={!values.defaultAmount ? true : false}>
      <Select.Trigger aria-label={selected.label} asChild>
        <UnstyledButton
          sx={(t) => ({
            background: t.colors.gray[1],
            borderRadius: t.radius.md,
            cursor: !values.defaultAmount ? 'not-allowed' : 'pointer',
            '&:hover': !values.defaultAmount ? undefined : { background: t.colors.gray[4] }
          })}
          px="xs"
          py={4}>
          <Group spacing="xs">
            <Box sx={() => ({ opacity: !values.defaultAmount ? 0.5 : 1 })}>
              <ChainIcon
                denom={selected.value === 'STRD' ? 'STRIDE' : selected.value}
                size={selected.value === 'STRD' ? 16 : 20}
              />
            </Box>

            <Text size="sm" sx={(t) => ({ color: !values.defaultAmount ? t.colors.gray[4] : t.colors.gray[7] })}>
              <Select.Value aria-label={selected.label}>{selected.label}</Select.Value>
            </Text>

            <Select.Icon asChild>
              <Icon
                name="selectCaret"
                sx={(t) => ({ color: !values.defaultAmount ? t.colors.gray[4] : t.colors.gray[7] })}
              />
            </Select.Icon>
          </Group>
        </UnstyledButton>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content position="popper" sideOffset={8} style={{ zIndex: 999 }}>
          <Select.Viewport>
            <Paper shadow="lg" p={4} radius="md" withBorder>
              <Stack spacing={2}>
                {options
                  .filter((option) => option.value !== value)
                  .map((option) => (
                    <Select.Item
                      key={option.value}
                      value={option.value}
                      textValue={option.label}
                      style={{ outline: 0 }}>
                      <UnstyledButton
                        sx={(t) => ({
                          background: 'transparent',
                          '&:hover': { background: t.colors.gray[2] },
                          cursor: 'pointer',
                          borderRadius: t.radius.md,
                          display: 'block',
                          width: '100%'
                        })}>
                        <Group spacing="xs" px="xs" py={4}>
                          <ChainIcon denom={option.value} size={20} />
                          <Text sx={(t) => ({ color: t.colors.gray[7] })}>{option.label}</Text>
                        </Group>
                      </UnstyledButton>
                    </Select.Item>
                  ))}
              </Stack>
            </Paper>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}

export { StakeAutopilotRecipientSelection }
