import { Icon, TextLoader, useFormContext } from '@/components'
import { Alert, Box, Group, Space, Text, TextInput, ActionIcon, Button, Paper, Stack } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import BigNumber from 'bignumber.js'
import { useMemo, useState } from 'react'
import { CHAIN_CONFIG, CHAIN_INFO_LIST } from '@/config'
import { StakeLsmEstimate } from './StakeLsmEstimate'
import { StakeFormType } from './useStakeForm'
import { useLsmValidatorsQuery } from '../queries'
import { StakeLsmSelection } from './StakeLsmSelection'
import { useStake } from '../StakeProvider'
import { LsmValidatorAvatar } from '../shared/LsmValidatorAvatar/LsmValidatorAvatar'
import { LsmValidatorName } from '../shared'
import { useSelectedBalancesV2, useSelectedCoin, useStrideWallet, useWallet } from '@/contexts/wallet'
import { convertDenomToMicroDenom, convertMicroDenomToDenom, formatMicroDenom } from '@/wallet-utils'
import { flushSync } from 'react-dom'
import { useLatestValue } from '@/hooks'
import { assert } from '@/utils'
import { ChainIcon } from '@/page-components/shared'

interface Props {
  amountInputRef: React.RefObject<HTMLInputElement>
}

const StakeLsmForm: React.FC<Props> = ({ amountInputRef }) => {
  const strideAccount = useStrideWallet()

  const denom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const minimum = CHAIN_CONFIG[denom].classicLsMinimum

  const { isFetchingSelectedAccount } = useWallet()

  const { data: selectedBalances } = useSelectedBalancesV2()

  const [isFocused, setIsFocused] = useState(false)

  const { lsm, setLsmValidatorAddress } = useStake()

  const { values, setFieldValue, validate } = useFormContext<StakeFormType>()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // `validate` seems to validate against a set of cached values (on the same time when the fn is created) rather than the latest values.
  // So, we'll store the latest reference to the validate function and use that instead.
  const validateRef = useLatestValue(validate)

  const { data: lsmValidators } = useLsmValidatorsQuery()

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const selectedValidator = useMemo(() => {
    return lsmValidators?.validators.find((validator) => {
      return validator.address === lsm.validatorAddress
    })
  }, [lsm, lsmValidators])

  const selectedValidatorStakedAmount = BigInt(selectedValidator?.amount ?? 0)

  const recommendedMaxAmount = useMemo(() => {
    const selectedValidatorStakedAmountInDenom = convertMicroDenomToDenom(
      selectedValidatorStakedAmount,
      chainInfo.stakeCurrency.coinMinimalDenom
    )

    const maxRoundedDown = new BigNumber(selectedValidatorStakedAmountInDenom).decimalPlaces(5).toNumber()
    return Math.max(maxRoundedDown, 0)
  }, [selectedValidatorStakedAmount, chainInfo.stakeCurrency.coinMinimalDenom])

  const halfAmount = useMemo(() => {
    const selectedValidatorStakedAmountInDenom = convertMicroDenomToDenom(
      selectedValidatorStakedAmount,
      chainInfo.stakeCurrency.coinMinimalDenom
    )

    const halfRoundedDown = new BigNumber(selectedValidatorStakedAmountInDenom).dividedBy(2).decimalPlaces(5)

    return BigNumber.max(halfRoundedDown, 0)
  }, [selectedValidatorStakedAmount, chainInfo.stakeCurrency.coinMinimalDenom])

  const handleClickMax = () => {
    // We'll need to flush the state to ensure that the validate function is updated first before we run it.
    // The other forms do not have this problem because the value of recommendedMaxAmount is the same max
    // value used in the validation.
    flushSync(() => setFieldValue('lsmAmount', String(recommendedMaxAmount)))

    assert(validateRef.current, 'Stale reference to validate function')
    // If we only call validate() here, it will use the stale reference to the validate function
    validateRef.current()
  }

  const handleClickHalf = () => {
    setFieldValue('lsmAmount', halfAmount.toString())
  }

  useDidUpdate(() => {
    if (!isFocused) return
    // We only want to validate if the user changed something,
    // but not if reset by a successful stake
    // We're strictly not validating when focus changes as it
    // causes the form to validate when we autofocus on mount
    // or when stake modal closes and reset the form
    const { errors } = validate()

    // We've removed the validation error where values.lsmAmount is required.
    if (typeof errors.lsmAmount === 'string' && values.lsmAmount !== '') {
      // We are storing the value of `errors.lsmAmount` as an internal state
      // because this solves an issue where the UI starts stuttering as the user
      // types on the input field.

      // @TODO: figure out why the stuttering happens and fix it
      setErrorMessage(errors.lsmAmount)
    } else {
      setErrorMessage(null)
    }
  }, [values.lsmAmount])

  // If the user is logged in with a wallet that does not have sufficient unstaked token (e.g., no ATOM),
  // we'll display a note that their transaction may fail.
  const isTransferringWithoutSufficientBalanceForGas = selectedBalances?.selectedAccountBalance
    ? BigInt(selectedBalances.selectedAccountBalance) < convertDenomToMicroDenom(CHAIN_CONFIG[denom].minimumGas, denom)
    : false

  const amountOption = useMemo(() => {
    if (values.lsmAmount === recommendedMaxAmount.toString()) return 'max'
    if (values.lsmAmount === halfAmount.toString()) return 'half'

    return null
  }, [values, recommendedMaxAmount, halfAmount])

  if (!selectedValidator) {
    return <StakeLsmSelection />
  }

  const handleUnselectValidator = () => {
    setLsmValidatorAddress('')

    setFieldValue('lsmAmount', '')
  }

  const formattedTotalBalance = formatMicroDenom(
    selectedValidatorStakedAmount,
    chainInfo.stakeCurrency.coinMinimalDenom,
    5
  )

  return (
    <>
      <Group spacing="sm">
        {lsmValidators && lsmValidators.validators.length > 1 && (
          <ActionIcon variant="filled" onClick={handleUnselectValidator}>
            <Box sx={{ transform: 'rotate(-90deg)' }}>
              <Icon name="caretDown" size={12} />
            </Box>
          </ActionIcon>
        )}

        <Group spacing="xs">
          <LsmValidatorAvatar address={selectedValidator.address} size="sm" />

          <Text inline size="lg" weight={600} sx={(t) => ({ color: t.colors.gray[9] })}>
            <LsmValidatorName address={selectedValidator.address} />
          </Text>
        </Group>
      </Group>

      <Space h="sm" />

      <Paper p="md" sx={{ backgroundColor: '#FAF6F8' }}>
        <Group position="apart" align="center">
          <Group spacing={0}>
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>Available:</Text>
            &nbsp;
            <TextLoader loading={isFetchingSelectedAccount} sx={(t) => ({ color: t.colors.gray[7] })}>
              {strideAccount && `${formattedTotalBalance} ${denom}`}
            </TextLoader>
          </Group>

          <Group spacing="xs">
            <Button
              size="xs"
              onClick={handleClickHalf}
              // We're disabling this button if the user has less than the minimum amount
              disabled={Number(formattedTotalBalance) <= minimum}
              sx={(t) => ({
                width: 44,
                height: 24,
                padding: 0,
                borderRadius: 4,
                backgroundColor: amountOption === 'half' ? t.colors.gray[7] : t.colors.gray[4],
                transition: 'background 0.1s',
                '&:not(.__mantine-ref-loading):disabled': {
                  backgroundColor: t.colors.gray[4],
                  color: t.white
                },
                '&:not(:disabled):active': {
                  backgroundColor: t.colors.pink[7],
                  transform: 'translateY(0)'
                }
              })}>
              <Text sx={(t) => ({ color: t.white })}>Half</Text>
            </Button>

            <Button
              size="xs"
              onClick={handleClickMax}
              // We're disabling this button if the user has less than the minimum amount
              disabled={Number(formattedTotalBalance) <= minimum}
              sx={(t) => ({
                width: 44,
                height: 24,
                padding: 0,
                borderRadius: 4,
                backgroundColor: amountOption === 'max' ? t.colors.gray[7] : t.colors.gray[4],
                transition: 'background 0.1s',
                '&:not(.__mantine-ref-loading):disabled': {
                  backgroundColor: t.colors.gray[4],
                  color: t.white
                },
                '&:not(:disabled):active': {
                  backgroundColor: t.colors.pink[7],
                  transform: 'translateY(0)'
                }
              })}>
              <Text sx={(t) => ({ color: t.white })}>Max</Text>
            </Button>
          </Group>
        </Group>

        <Space h="xs" />

        <Paper
          p="sm"
          sx={(t) => ({
            backgroundColor: t.white,
            transition: 'border 0.15s ease-in-out',
            border: `2px solid ${isFocused ? t.colors.gray[3] : 'transparent'}`
          })}
          onClick={() => amountInputRef.current?.focus()}>
          <Group position="apart" noWrap>
            <Group spacing={10} noWrap sx={{ width: 'fit-content' }}>
              <ChainIcon denom={denom} size={40} />

              <Stack spacing={4}>
                <Text size="xl" weight={700} sx={(t) => ({ color: t.colors.gray[9], lineHeight: 1 })}>
                  {denom}
                </Text>

                <Text sx={(t) => ({ color: t.colors.gray[7], lineHeight: 1, whiteSpace: 'nowrap' })}>
                  {chainInfo.chainName}
                </Text>
              </Stack>
            </Group>

            <Stack spacing={0} align="flex-end">
              <TextInput
                ref={amountInputRef}
                required
                size="xs"
                sx={(t) => ({
                  input: {
                    fontWeight: 700,
                    fontSize: 20,
                    textAlign: 'right',
                    color: t.colors.gray[9],
                    padding: 0,
                    border: 'none',
                    borderRadius: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    '&::placeholder': { color: t.colors.gray[9] }
                  }
                })}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue('lsmAmount', e.target.value)}
                value={values.lsmAmount}
              />

              <StakeLsmEstimate hideDenomEstimate />
            </Stack>
          </Group>
        </Paper>
      </Paper>

      {errorMessage && (
        <>
          <Space h="xs" />

          <Text sx={(t) => ({ color: t.colors.red[7] })}>{errorMessage}</Text>
        </>
      )}

      {isTransferringWithoutSufficientBalanceForGas && (
        <>
          <Space h="xs" />

          <Alert color="yellow">
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>
              It looks like you don't have enough unstaked {denom}. We recommend to have at least{' '}
              {CHAIN_CONFIG[denom].minimumGas} unstaked {denom} in your wallet to cover the gas fees associated with
              liquid staking. The transaction may fail if you ran out of gas.
            </Text>
          </Alert>
        </>
      )}

      <Space h="lg" />

      <Group position="apart" align="center" sx={{ height: 50 }}>
        <Text inline sx={(t) => ({ color: !values.lsmAmount ? t.colors.gray[4] : t.colors.gray[7] })}>
          You will get:
        </Text>

        <StakeLsmEstimate />
      </Group>
    </>
  )
}

export { StakeLsmForm }
