import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { fatal } from '@/utils'
import { CHAIN_CONFIG } from '@/config'
import { calculateFee, convertDenomToMicroDenom, isSafeModeAccount } from '@/wallet-utils'
import {
  generateIbcTimeoutTimestamp,
  signIbcTransaction,
  IbcReturnType,
  simulateIbcTransaction
} from '@/wallet-utils/ibc'
import { stakeAmountAtom, stakeAutopilotRecipientAtom } from '../../atoms'
import { MutationParameters } from './types'
import { useSelectedCoin, useSelectedWallet, useStrideWallet, useWalletAddresses } from '@/contexts/wallet'
import { useReferral } from '@/page-components/Referral'

const useSignStakeAutopilotMutation = ({ setStakeAutopilotRaw }: MutationParameters) => {
  const { memo } = useReferral()

  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const selectedCoinDenom = useSelectedCoin()

  const amount = useAtomValue(stakeAmountAtom)

  const stakeAutopilotRecipient = useAtomValue(stakeAutopilotRecipientAtom)

  const { data: addresses } = useWalletAddresses([stakeAutopilotRecipient])

  const handleMutation = async (): Promise<IbcReturnType> => {
    if (!strideAccount || !selectedAccount || !addresses) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    const amountInMicroDenom = convertDenomToMicroDenom(amount, selectedAccount.currency.coinMinimalDenom)

    if (isSafeModeAccount(selectedAccount)) throw fatal('Safe mode is enabled.')

    const autopilot = JSON.stringify({
      autopilot: {
        stakeibc: {
          stride_address: strideAccount.address,
          action: 'LiquidStake',
          // Set ibc_receiver only for non-Stride destinations
          ibc_receiver: stakeAutopilotRecipient !== 'STRD' ? addresses[0].address : undefined,
          // Provide transfer_channel only if:
          // 1. The recipient is not the selected wallet
          // 2. The recipient is not Stride
          transfer_channel: ![selectedCoinDenom, 'STRD'].includes(stakeAutopilotRecipient)
            ? CHAIN_CONFIG[stakeAutopilotRecipient].withdrawChannel
            : undefined
        },
        receiver: strideAccount.address
      }
    })

    const msgTransfer = {
      typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
      value: {
        sourcePort: 'transfer',
        sourceChannel: CHAIN_CONFIG[selectedAccount.currency.coinDenom].depositChannel,
        token: {
          amount: amountInMicroDenom.toString(),
          denom: selectedAccount.currency.coinMinimalDenom
        },
        sender: selectedAccount.address,
        receiver: strideAccount.address,
        memo: autopilot,
        timeoutTimestamp: generateIbcTimeoutTimestamp()
      }
    }

    const gas = await simulateIbcTransaction(selectedAccount, selectedAccount.address, [msgTransfer], '')
    const fee = calculateFee(selectedAccount, gas)

    return await signIbcTransaction(selectedAccount, selectedAccount.address, [msgTransfer], fee, memo)
  }

  const handleSuccess = (raw: IbcReturnType) => {
    setStakeAutopilotRaw(raw)
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

export { useSignStakeAutopilotMutation }
