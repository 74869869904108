import React from 'react'
import { Anchor, Box, Center, Image, Stack, Text } from '@mantine/core'
import { useSelectedCoin } from '@/contexts/wallet'
import { useMediumScreen } from '@/hooks'

const StakeDydxBanner: React.FC = () => {
  const { isMediumScreen } = useMediumScreen()

  const denom = useSelectedCoin()

  if (denom !== 'DYDX') {
    return null
  }

  return (
    <Anchor
      href="https://help.dydx.trade/en/articles/166999-secret-phrase-on-dydx-chain"
      target="_blank"
      sx={{ '&:hover': { textDecoration: 'none' } }}
      inline>
      <Box
        sx={(t) => ({
          position: 'relative',
          background: '#5B002C',
          overflow: 'hidden',
          borderRadius: t.radius.md
        })}>
        <Box sx={{ position: 'absolute', right: isMediumScreen ? 64 : 24, top: 0, bottom: 0 }}>
          <Center sx={{ height: '100%', width: 118 }}>
            <Image src="/banners/dydx-bg.svg" />
          </Center>
        </Box>

        <Stack justify="space-between" p="md" sx={{ height: isMediumScreen ? 143 : 'auto' }}>
          <Box>
            <Box>
              <Box
                component="span"
                sx={{ display: 'inline-block', width: 20, marginRight: 8, transform: 'translateY(4px)' }}>
                <Image src="/banners/dydx-metamask.svg" />
              </Box>

              <Text color="white" size="md" weight={600} component="span">
                MetaMask user?
              </Text>
            </Box>

            <Text sx={(t) => ({ color: t.colors.gray[5] })} component="span">
              Set up your Keplr wallet with the official migration guide
            </Text>
          </Box>

          <Text sx={{ color: '#FF3D8E' }} component="span" weight={600} inline>
            Learn more &ndash;&gt;
          </Text>
        </Stack>
      </Box>
    </Anchor>
  )
}

export { StakeDydxBanner }
